<template>
    <v-menu offset-y>
        <template #activator="{ on, attrs }">
            <v-btn :disabled="!hasAccessRole" v-bind="attrs" class="primary ml-4" v-on="on"
                >Add Export
            </v-btn>
        </template>
        <template>
            <v-list v-if="Object.keys(exportProducts).length > 0">
                <v-list-item
                    v-for="(product, productId) in cfgProducts"
                    :key="productId"
                    link
                    :to="{ name: 'export-add', params: { productId } }"
                >
                    {{ product.name }}
                </v-list-item>
            </v-list>
            <v-list v-else>
                <v-list-item>No Available Products</v-list-item>
            </v-list>
        </template>
    </v-menu>
</template>

<script>
import cfgProducts from '@/helpers/mappings/cfgProduct'
import { ROLE_SETUP_EXPORT_PRODUCT_CREATE } from '@/helpers/security/roles'
import { mapActions, mapGetters } from 'vuex'

export default {
    data: () => ({
        cfgProducts,
        exportProducts: {},
    }),
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            selectedDistrict: 'getSelectedDistrict',
            getClientProducts: 'client/getClientProducts',
        }),
        hasAccessRole() {
            return this.hasRole(ROLE_SETUP_EXPORT_PRODUCT_CREATE)
        },
        clientProducts() {
            return this.getClientProducts(this.selectedDistrict.illuminateClientId)
        },
    },
    async mounted() {
        const clientId = this.selectedDistrict?.illuminateClientId
        if (clientId) {
            await this.fetchClientProducts(clientId)

            // filter applications the client has.
            this.exportProducts = Object.entries(this.cfgProducts).reduce((acc, [id, product]) => {
                if (this.clientProducts.find((p) => product.app.includes(p.productApp))) {
                    acc[id] = product
                }
                return acc
            }, {})
        }
    },
    methods: {
        ...mapActions({
            fetch: 'export/get',
            fetchClientProducts: 'client/getProducts',
        }),
    },
}
</script>

<style></style>
